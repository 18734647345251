$(".faq__item").click(function (e) {
    if (!$(e.currentTarget).is('.active')) {
        $(".faq__answer").slideUp(300);
        $(".faq__item").removeClass("active");
        $(e.currentTarget).toggleClass("active");
        $(e.currentTarget).find(".faq__answer").slideToggle(300);
        console.log(1)
    }
    else{
        $(e.currentTarget).toggleClass("active");
        $(e.currentTarget).find(".faq__answer").slideToggle(300);
        console.log(2)
    }
})